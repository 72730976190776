export const IMAGE_FRAGMENT = `data {
    id
    attributes {
    name
    caption
    width
    height
    url
    formats
    alternativeText
    ext
    hash
    mime
    previewUrl
    provider
    provider_metadata
    size
    }
}`
